<template>
  <item-card className="-mx-4 sm:-mx-6">
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Bildes
      </h3>
    </template>
    <template v-slot:buttons>

      <template v-if="!displayForm('UploadImages')">
        <Button icon="plus" @click="showForm('UploadImages')" />
      </template>

    </template>
    <template v-slot:content>
      <template v-if="!displayForm('UploadImages')">

        <template v-if="item.images && item.images.length">
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              <template v-for="(image, index) in item.images" :key="index">
                <div class="col-span-1 flex items-center justify-center">
                  <ShowImage :image="image" :item="item"/>
                </div>
              </template>
          </div>
        </template>

      </template>

      <template v-else>
        <AddInvoiceImages :item="item"/>
      </template>

    </template>
  </item-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemCard from "@/components/Components/ItemCard"
import ShowImage from "@/components/Invoices/Invoice/Images/ShowImage"
import AddInvoiceImages from "@/components/Invoices/Invoice/Images/AddInvoiceImages"

export default {
  name: "InvoiceImages",
  components: {
    ItemCard,
    ShowImage,
    AddInvoiceImages
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay'
    }),
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  },
}
</script>

<style>

</style>